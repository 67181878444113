import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactUs from "../components/Contact/ContactUs"

const ContactUsPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactUs />
  </Layout>
)

export default ContactUsPage
