import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "../Button"
import ContactBg from "../../assets/images/contact.jpg"

import { motion } from "framer-motion"

const ContactUs = () => {
  const [fullname, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [msg, setMsg] = useState("")

  const handleFullnameChange = e => {
    setFullname(e.target.value)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handlePhoneChange = e => {
    setPhone(e.target.value.substr(0, 10))
  }

  const handleMsgChange = e => {
    setMsg(e.target.value)
  }

  return (
    <ContactContainer>
      <ContactContent>
        <motion.h1
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Contact Us
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          Make sure to contact us (not ready yet)
        </motion.p>
        <motion.form
          initial={{ opacity: 0, x: "-100vw" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.1, delay: 1.5 }}
        >
          <FormWrap>
            <label htmlFor="fullname">
              <input
                type="text"
                value={fullname}
                onChange={handleFullnameChange}
                placeholder="Full Name"
                id="fullname"
              />
            </label>
            <label htmlFor="email">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                id="email"
              />
            </label>
            <label htmlFor="phone">
              <input
                type="number"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Phone"
                id="phone"
              />
            </label>
            <label htmlFor="msg">
              <textarea
                type="number"
                value={msg}
                onChange={handleMsgChange}
                placeholder="Message"
                id="msg"
              />
            </label>
            <Button
              as="button"
              type="submit"
              primary="true"
              round="true"
              css={`
                height: 48px;

                @media screen and (max-width: 768px) {
                  width: 100%;
                  min-width: 350px;
                }

                @media screen and (max-width: 400px) {
                  width: 100%;
                  min-width: 250px;
                }
              `}
            >
              Submit
            </Button>
          </FormWrap>
        </motion.form>
      </ContactContent>
    </ContactContainer>
  )
}

export default ContactUs

const ContactContainer = styled.div`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 35%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url(${ContactBg}) no-repeat center;
  background-size: cover;
  height: 650px;
  width: 100%;
  padding: 5rem calc((100vw - 1300px) / 2);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: clamp(1rem, 5vw, 3rem);
    padding: 0 1rem;
  }

  p {
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  form {
    z-index: 10;
  }
`

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 400px;

  input {
    padding: 1rem 1.5rem;
    outline: none;
    width: 350px;
    height: 48px;
    border: none;
    font-size: 18px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    resize: none;
    padding: 1rem 1.5rem;
    outline: none;
    width: 350px;
    height: 60px;
    border: none;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem;

    input {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
`
